
    import { defineComponent, ref, watch, onMounted } from 'vue';
    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import AuthService from "@/services/AuthService";
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import ApiService from '@/services/ApiService';
    import { getOptionsItensReprovados } from '@/services/OficinaDigital';
    import { useStore } from 'vuex';
    import { Actions } from "@/store/enums/StoreEnums";
    import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';

    export default defineComponent({
        name: "filtro-itens-reprovados",
        components: {
            FilterBase,
            ElConfigProvider,
        },

        setup(props, { emit }) {
            const store = useStore();

            const dataInicialPeriodo = moment().subtract(1, 'months').toISOString();
            const dataFInalPeriodo = moment().toISOString();
            const valorInicialData = [dataInicialPeriodo, dataFInalPeriodo];
            const disabledClick = ref(false);

            let valorInicialCodConcessionaria;
            if(window.localStorage.getItem("id_usuario")){
                valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
            } else {
                valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
            }
            const codConcessionariaSelected:any = ref(valorInicialCodConcessionaria ?? "");
            const datePicker = ref(valorInicialData);

            const userInfo = AuthService.getUsuarioAuth();
            const token = userInfo.token;
            const emitter = useEmitter();

            const activeModal = ref(false);
            const closeModal = ref(false);
            const placa:any=ref("");
            const label = ref("Faça filtros por concessionária, data e placa");
            
            const lastEmitIsNull = ref(false);
            const modalFiltro:any = ref();
            const showClose = ref(false)
            const motivoSelected:any= ref([]);
            const statusSelected:any= ref([]);
            const pecaSelected:any= ref([]);
            const optionsConcessionaria:any= ref([]);
            const optionsMotivosReprovacao:any= ref([]);
            const optionsPecas:any= ref([]);
            const auxSearchValuePeca:any=ref("");
            const auxOptionsPecas:any= ref([]);
            const optionsNegociacao:any= [
                {
                    text: "Aceita negociação",
                    value: true,
                },
                {
                    text: "Não Aceita negociação",
                    value: false,
                },
            ];
            const loading:any= ref({
                concessionaria: false,
                filtrosReprovados: false,
            });
            const showGoToBack = ref(false);

            onMounted(() => {
                if(store.getters.concessionariasList.length > 0){
                    trataConcessionarias();
                }
            });

            watch(datePicker, () => {
                if(datePicker.value == null){
                    datePicker.value = [...valorInicialData];
                    return;
                };
            });

            store.watch(() => store.getters.concessionariasList, () => {
                trataConcessionarias();
            }, { deep: true });

            store.watch(() => store.getters.concessionariaSelectedInfo.codConcessionaria, () => {
                if(store.getters.concessionariaSelectedInfo.codConcessionaria){
                    getItensReprovadosOptions();
                }
            }, { deep: true });

            function truncateWord(word: String, maxLength: number){
               let  myTruncateWord = "";
                if(word.length > maxLength){
                    myTruncateWord = word.substring(0, maxLength);
                    myTruncateWord += "...";

                    return myTruncateWord;
                }

                return word;
            }

            async function getItensReprovadosOptions() {
                loading.value.filtrosReprovados = true;
                const filtersData = retornaFiltroData(datePicker.value[0], datePicker.value[1]);
                const response = await getOptionsItensReprovados(codConcessionariaSelected.value, filtersData, );
                const motivosReprovacao = response.motivosReprovacao;
                const pecas = response.pecas;
                pecaSelected.value = [];
                motivoSelected.value = [];

                optionsMotivosReprovacao.value = motivosReprovacao.map(motivo => {
                    return {
                        text: motivo ?? "Sem motivo",
                        value: motivo,
                    }
                });
                optionsPecas.value = pecas.map(peca => {
                    return {
                        text: truncateWord(peca, 50),
                        value: peca,
                    }
                });

                auxOptionsPecas.value = [...optionsPecas.value]
                loading.value.filtrosReprovados = false;
            };

            async function trataConcessionarias() {
                const concessionarias = store.getters.concessionariasList;
                optionsConcessionaria.value = concessionarias.map(concessionaria => {
                    return {
                    text: concessionaria.nomeFantasia,
                    value: concessionaria.codConcessionaria,
                    disabled: !concessionaria.temOficinaDigital,
                    }
                }).sort((concessionariaA, concessionariaB) => {
                    if (!concessionariaA.disabled && concessionariaB.disabled) {
                    return -1;
                    }
                    if (!concessionariaB.disabled && concessionariaA.disabled) {
                    return 1;
                    }

                    if (concessionariaA.disabled && !concessionariaA.disabled) {
                    if (concessionariaA.text < concessionariaB.text){
                        return -1;
                    }
                    if (concessionariaA.text > concessionariaB.text){
                        return 1;
                    }
                    return 0;
                    }

                    return 0;
                });
                
                const cacheConcessionariaSelected = store.getters.concessionariaSelectedInfo;
                if(cacheConcessionariaSelected.codConcessionaria){
                    if(cacheConcessionariaSelected.temOficinaDigital){
                        codConcessionariaSelected.value = cacheConcessionariaSelected.codConcessionaria;
                        emiteMudanca(datePicker.value);
                        getItensReprovadosOptions();
                    } else {
                        showGoToBack.value = true;
                        label.value = "Selecione uma concessionária com oficina digital";
                        codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
                        activeModal.value = !activeModal.value;
                        showClose.value = false;
                    }
                } else {
                    codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
                    activeModal.value = !activeModal.value;
                    showClose.value = false;
                }
            };

            function desabilitaDatasAnteriores (data){
                const dataAnterior = data.getTime() > new Date("2016-01-01T00:00:00") === data.getTime() > Date.now()
                return dataAnterior
            };
            
            function selectAllFilterPeca(){
                pecaSelected.value = optionsPecas.value.map(peca => peca.value);
            }

            function resetFilterPeca() {
                auxSearchValuePeca.value = false
    	        optionsPecas.value = [...auxOptionsPecas.value]
            }

            function filterPeca(searchValue) {{
                auxSearchValuePeca.value = searchValue;
                if(!searchValue){
                    optionsPecas.value = [...auxOptionsPecas.value]
                    return;
                }

                optionsPecas.value = auxOptionsPecas.value.filter(item => item.text.toLowerCase().includes(searchValue.toLowerCase()));
            }}

            function verificaIntervalo(data){
                var diff = moment(data[1]).diff(moment(data[0]), "months");
                if(diff <= 24) {
                    emiteMudanca(datePicker.value); 
                    closeModal.value = !closeModal.value;
                } else {
                   Swal.fire({
                        title: "Período inválido!",
                        html: `<h4>Não é possível filtrar um período superior a 24 meses (2 anos)!</h4>`,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok!",
                        customClass: {
                        confirmButton: "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
                        },
                    });
                }
            };

            function retornaFiltroData(dataInicial, dataFinal){
                dataInicial = moment(dataInicial).startOf('day').format("YYYY-MM-DD HH:mm:ss");
                dataFinal = moment(dataFinal).endOf('day').format("YYYY-MM-DD HH:mm:ss");

                const filters = `${dataInicial}/${dataFinal}`;
                return filters;
            };

            async function emiteMudanca(valorData){
                label.value = "Faça filtros por concessionária, data,  e placa";
                showGoToBack.value = false;
                ///// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
                await ApiService.valideTokenTime();
                
                /////
                if(window.localStorage.getItem("id_usuario")){
                    window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                } else {
                    window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                }
             
                
                if(valorData == null){
                    if(lastEmitIsNull.value){
                        return;
                    };
                    
                    lastEmitIsNull.value = true;
                    const dataTratada = retornaFiltroData(valorInicialData[0], valorInicialData[1]);
                    store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                    emitter.emit("filtrar-od-reprovados", { data: dataTratada, codConcessionaria: codConcessionariaSelected.value, filtrosReprovados: { motivosReprovacao: motivoSelected.value, pecas: pecaSelected.value, statusNegociacao: statusSelected.value, placa: placa.value }});
                    return;
                };

                lastEmitIsNull.value = false;
                const dataTratada = retornaFiltroData(valorData[0], valorData[1]);
                store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                emitter.emit("filtrar-od-reprovados", { data: dataTratada, codConcessionaria: codConcessionariaSelected.value, filtrosReprovados: { motivosReprovacao: motivoSelected.value, pecas: pecaSelected.value, statusNegociacao: statusSelected.value, placa: placa.value }});
            };

            return {
                activeModal,
                closeModal,
                showClose,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                valorInicialData, 
                lastEmitIsNull, 
                desabilitaDatasAnteriores,
                optionsConcessionaria,
                optionsMotivosReprovacao,
                optionsPecas,
                codConcessionariaSelected,
                verificaIntervalo,
                motivoSelected,
                pecaSelected,
                getItensReprovadosOptions,
                showGoToBack,
                label,
                statusSelected,
                optionsNegociacao,
                disabledClick,
                placa,
                selectAllFilterPeca,
                filterPeca,
                auxSearchValuePeca,
                resetFilterPeca
            };
        }
    });
